export const house = [
    {
        id: 1,
        image: "5448.jpg",
        price: "$390,000",
        details: "4 bd | 2 ba | 2,156 sqft",
        address: "5448 E 142nd Pl N",
        city: "Collinsville, OK 74021",
        link: "https://www.zillow.com/homes/5448-E-142nd-Pl-N-Collinsville,-OK-74021_rb/2072223720_zpid/",
    },

    {
        id: 2,
        image: "2318.jpg",
        price: "$455,000",
        details: "4 bd | 4 ba | 3,227 sqft",
        address: "2318 E 138th St S",
        city: "Bixby, OK 74008",
        link: "https://www.zillow.com/homes/2318-E-138th-St-S-Bixby,-OK-74008_rb/89340039_zpid/",
    },
    {
        id: 3,
        image: "3309.jpg",
        price: "$391,400",
        details: "4 bd | 3 ba | 2,994 sqft",
        address: "3309 W Jackson St",
        city: "Broken Arrow, OK 74012",
        link: "https://www.zillow.com/homedetails/3309-W-Jackson-St-Broken-Arrow-OK-74012/94722093_zpid/",
    },
    {
        id: 4,
        image: "3800.jpg",
        price: "$392,500",
        details: "4 bd | 4 ba | 3,103 sqft",
        address: "3800 W Utica St",
        city: "Broken Arrow, OK 74011",
        link: "https://www.zillow.com/homes/3800-W-Utica-St-Broken-Arrow,-OK-74011_rb/70508656_zpid/",
    },
    {
        id: 5,
        image: "117.jpg",
        price: "$359,900",
        details: "4 bd | 3 ba | 2,286 sqft",
        address: "117 Castle Creek Dr",
        city: "Sapulpa, OK 74066",
        link: "https://www.zillow.com/homes/117-Castle-Creek-Dr-Sapulpa,-OK-74066_rb/212982020_zpid/",
    },
];